export default {
  methods: {
    formatDate: function(dateToFormat, formatOptions) {
      if (dateToFormat===null || dateToFormat==="" || dateToFormat===undefined) {
        return "";
      }

      if (typeof dateToFormat === 'string') {
        return this.formatDateFromString(dateToFormat, formatOptions);
      }

      return new Date(dateToFormat).toLocaleDateString("en-US", formatOptions);
    },

    formatDateFromString: function(dateToFormat, formatOptions) {
      let asDate = this.getDateFromString(dateToFormat)

      return asDate.toLocaleDateString("en-US", formatOptions);
    },

    formatDateTimeNoSeconds: function(dateToFormat) {
      return this.formatDateTime(dateToFormat, {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})
    },

    formatDateTimeLongNoSeconds: function(dateToFormat) {
      return this.formatDateTime(dateToFormat, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })
    },

    formatDateTime: function(dateTimeToFormat, options) {
      if (dateTimeToFormat===null || dateTimeToFormat==="" || dateTimeToFormat===undefined) {
        return ""
      }

      if (typeof dateTimeToFormat === 'string') {
        return this.formatDateTimeFromString(dateTimeToFormat, options || {})
      }

      return new Date(dateTimeToFormat).toLocaleString("en-US", options)
    },

    formatDateTimeFromString: function(dateToFormat, options) {
      let asDate = this.getDateFromString(dateToFormat)

      return asDate.toLocaleString("en-US", options || {});
    },

    getWeeksFromToday(weeks) {
      let weeksFromToday = new Date()
      weeksFromToday.setDate(weeksFromToday.getDate() + (weeks * 7));

      return weeksFromToday
    },

    formatToIsoDate: function(dateToFormat) {
      if (dateToFormat instanceof Date) {
        return dateToFormat.toISOString().split('T')[0];
      }
    },

    getDateFromString: function(dateString) {
      let finalDate = dateString

      // safari has issues with parsing date strings with +0000 at the end
      if (dateString.indexOf('+') !== -1) {
        finalDate = dateString.substr(0, dateString.indexOf('+'))
      }

      return new Date(finalDate)
    },

    getDateObject: function(date) {
      if (date===null || date==="" || date===undefined) {
        return null;
      }

      if (typeof date === 'string') {
        return this.getDateFromString(date)
      }

      return new Date(date)
    },

    addDaysToDate(date, daysToAdd) {
      if (typeof date === 'string') {
        date = this.getDateFromString(date)
      }

      let newDate = new Date(date.valueOf())
      newDate.setDate(date.getDate() + daysToAdd);

      return newDate
    },

    dateUnavailablePlaceholder() {
      return this.$constants.DateUnavailablePlaceholder
    }

  }
}